import { Big6Math, HermesClient, PositionSide, SupportedChainId as PerennialSupportedChainIdType } from '@perennial/sdk';
import { Address, Chain, EIP1193RequestFn, TransportConfig, createPublicClient, http } from "viem"
import { Connector, createConfig } from "wagmi"
import {
  arbitrum,
  arbitrumSepolia,
  aurora,
  avalanche,
  base,
  baseSepolia,
  blast,
  bsc,
  boba,
  celo,
  gnosis,
  fantom,
  fraxtal,
  fuse,
  linea,
  mainnet,
  mantle,
  metis,
  mode,
  moonriver,
  moonbeam,
  optimism,
  polygon,
  polygonZkEvm,
  rootstock,
  scroll,
  seiDevnet,
  sepolia,
  zkSync
} from "viem/chains"
import { connectorsForWallets } from '@rainbow-me/rainbowkit'
import {
  metaMaskWallet,
  rainbowWallet,
  walletConnectWallet,
  coinbaseWallet,
  trustWallet,
  okxWallet
} from "@rainbow-me/rainbowkit/wallets"
import { StoreApi } from 'zustand/vanilla';


export enum CryptexProducts {
  Governance = "GOVERNANCE",
  Indexes = "INDEXES",
  Perpetuals = "PERPETUALS",
  PerpetualsV1 = "PERPETUALS_V1",
  V1 = "V1",
  Spot = "SPOT",
}

export const DefaultChain = arbitrum;

export const SupportedChainIds = [mainnet.id, sepolia.id, arbitrum.id, arbitrumSepolia.id, base.id, baseSepolia.id]

export const chainIdToChainMap = {
  [mainnet.id]: mainnet,
  [sepolia.id]: sepolia,
  [arbitrum.id]: arbitrum,
  [arbitrumSepolia.id]: arbitrumSepolia,
  [base.id]: base,
  [baseSepolia.id]: baseSepolia,
}

export type SupportedChainIdType = (typeof SupportedChainIds)[number];

export const isSupportedChain = (chainId: number): boolean => {
  const chainsIds = SupportedChainIds as Array<number>
  return chainsIds.includes(chainId)
}

export const PerpetualsGraphUrls: { [chainId in PerennialSupportedChainIdType]: string } = {
  [arbitrum.id]: "https://subgraph.satsuma-prod.com/0b9d5e79fdb9/cryptex/perennial-v2-arbitrum-new/api", // process.env.REACT_APP_GRAPH_URL_ARBITRUM ?? "",
  [arbitrumSepolia.id]: "https://subgraph.satsuma-prod.com/0b9d5e79fdb9/cryptex/perennial-v2-arbitrumSepolia-new/api", //process.env.REACT_APP_GRAPH_URL_ARBITRUM_SEPOLIA ?? "",
};

export const V1GraphUrls: { [chainId in SupportedChainIdType]: string } = {
  [mainnet.id]: process.env.REACT_APP_V1_GRAPH_URL_MAINNET ?? "",
  [sepolia.id]: process.env.REACT_APP_V1_GRAPH_URL_SEPOLIA ?? "",
  [arbitrum.id]: process.env.REACT_APP_V1_GRAPH_URL_ARBITRUM ?? "",
  [arbitrumSepolia.id]: process.env.REACT_APP_V1_GRAPH_URL_ARBITRUM_SEPOLIA ?? "",
  [base.id]: "",
  [baseSepolia.id]: "",
};

export const interfaceFeeBps: {
  [chainId in PerennialSupportedChainIdType]: { feeAmount: { [key in PositionSide]: bigint }; feeRecipientAddress: Address }
} = {
  [arbitrum.id]: {
    feeAmount: {
      [PositionSide.short]: Big6Math.fromFloatString('0.0002'), // 2bps
      [PositionSide.long]: Big6Math.fromFloatString('0.0002'), // 2bps
      [PositionSide.maker]: 0n,
      [PositionSide.none]: 0n
    },
    feeRecipientAddress: "0x9474B771Fb46E538cfED114Ca816A3e25Bb346CF",
  },
  [arbitrumSepolia.id]: {
    feeAmount: {
      [PositionSide.short]: Big6Math.fromFloatString('0.0002'), // 2bps
      [PositionSide.long]: Big6Math.fromFloatString('0.0002'), // 2bps
      [PositionSide.maker]: 0n,
      [PositionSide.none]: 0n,
    },
    feeRecipientAddress: "0x9474B771Fb46E538cfED114Ca816A3e25Bb346CF",
  },
}

const WalletConnectProjectId = process.env.REACT_APP_WALLET_CONNECT_ID || "";

export const AlchemyKey = process.env.REACT_APP_ALCHEMY_KEY || ""
export const AlchemySepoliaKey = process.env.REACT_APP_ALCHEMY_KEY || ""
export const AlchemyArbitrumKey = process.env.REACT_APP_ALCHEMY_ARBITRUM_KEY || ""
export const AlchemyArbitrumSepoliaKey = process.env.REACT_APP_ALCHEMY_ARBITRUM_KEY_SEPOLIA || ""
export const AlchemyBaseKey = process.env.REACT_APP_ALCHEMY_BASE_KEY || ""
export const AlchemyBaseSepoliaKey = process.env.REACT_APP_ALCHEMY_BASE_KEY_SEPOLIA || ""


export const rpcUrls: { [chainId in SupportedChainIdType]: string } = {
  [arbitrum.id]: `https://arb-mainnet.g.alchemy.com/v2/${AlchemyArbitrumKey}`,
  [arbitrumSepolia.id]: `https://arb-sepolia.g.alchemy.com/v2/${AlchemyArbitrumSepoliaKey}`,
  [base.id]: `https://base-mainnet.g.alchemy.com/v2/${AlchemyBaseKey}`,
  [baseSepolia.id]: `https://base-sepolia.g.alchemy.com/v2/${AlchemyBaseSepoliaKey}`,
  [mainnet.id]: `https://eth-mainnet.g.alchemy.com/v2/${AlchemyKey}`,
  [sepolia.id]: `https://eth-sepolia.g.alchemy.com/v2/${AlchemySepoliaKey}`,
}

export const getPublicClient = (chainId: SupportedChainIdType) => { 
  return createPublicClient({
    batch: {
      multicall: true,
    },
    chain: chainIdToChainMap[chainId],
    transport: http(rpcUrls[chainId])
  })
}

const connectors = connectorsForWallets([{
    groupName: "Recommended",
    wallets: [metaMaskWallet, walletConnectWallet, rainbowWallet, coinbaseWallet, okxWallet, trustWallet],
  }],
  {
    appName: 'Cryptex Finance',
    projectId: WalletConnectProjectId,
  }
);

export const wagmiConfig = createConfig({
  ssr: false,
  chains: [
    mainnet,
    arbitrum, 
    base,
    optimism,
    polygon,
    zkSync,
    avalanche,
    bsc,
    blast,
    mantle,
    polygonZkEvm,
    scroll,
    aurora,
    boba,
    celo,
    gnosis,
    fantom,
    fraxtal,
    fuse,
    linea,
    metis,
    mode,
    moonriver,
    moonbeam,
    rootstock,
    seiDevnet,
    sepolia,
    arbitrumSepolia,
    baseSepolia,
  ],
  transports: {
    [arbitrum.id]: http(rpcUrls[arbitrum.id]),
    [arbitrumSepolia.id]: http(rpcUrls[arbitrumSepolia.id]),
    [base.id]: http(rpcUrls[base.id]),
    [baseSepolia.id]: http(rpcUrls[baseSepolia.id]),
    [mainnet.id]: http(rpcUrls[mainnet.id]),
    [sepolia.id]: http(rpcUrls[sepolia.id]),
    1313161554: function (params: { chain?: Chain | undefined; pollingInterval?: number | undefined; retryCount?: number | undefined; timeout?: number | undefined; } & { connectors?: StoreApi<Connector[]> | undefined; }): { config: TransportConfig<string, EIP1193RequestFn>; request: EIP1193RequestFn; value?: Record<string, any> | undefined; } {
      throw new Error('Function not implemented.');
    },
    43114: function (params: { chain?: Chain | undefined; pollingInterval?: number | undefined; retryCount?: number | undefined; timeout?: number | undefined; } & { connectors?: StoreApi<Connector[]> | undefined; }): { config: TransportConfig<string, EIP1193RequestFn>; request: EIP1193RequestFn; value?: Record<string, any> | undefined; } {
      throw new Error('Function not implemented.');
    },
    288: function (params: { chain?: Chain | undefined; pollingInterval?: number | undefined; retryCount?: number | undefined; timeout?: number | undefined; } & { connectors?: StoreApi<Connector[]> | undefined; }): { config: TransportConfig<string, EIP1193RequestFn>; request: EIP1193RequestFn; value?: Record<string, any> | undefined; } {
      throw new Error('Function not implemented.');
    },
    81457: function (params: { chain?: Chain | undefined; pollingInterval?: number | undefined; retryCount?: number | undefined; timeout?: number | undefined; } & { connectors?: StoreApi<Connector[]> | undefined; }): { config: TransportConfig<string, EIP1193RequestFn>; request: EIP1193RequestFn; value?: Record<string, any> | undefined; } {
      throw new Error('Function not implemented.');
    },
    42220: function (params: { chain?: Chain | undefined; pollingInterval?: number | undefined; retryCount?: number | undefined; timeout?: number | undefined; } & { connectors?: StoreApi<Connector[]> | undefined; }): { config: TransportConfig<string, EIP1193RequestFn>; request: EIP1193RequestFn; value?: Record<string, any> | undefined; } {
      throw new Error('Function not implemented.');
    },
    100: function (params: { chain?: Chain | undefined; pollingInterval?: number | undefined; retryCount?: number | undefined; timeout?: number | undefined; } & { connectors?: StoreApi<Connector[]> | undefined; }): { config: TransportConfig<string, EIP1193RequestFn>; request: EIP1193RequestFn; value?: Record<string, any> | undefined; } {
      throw new Error('Function not implemented.');
    },
    250: function (params: { chain?: Chain | undefined; pollingInterval?: number | undefined; retryCount?: number | undefined; timeout?: number | undefined; } & { connectors?: StoreApi<Connector[]> | undefined; }): { config: TransportConfig<string, EIP1193RequestFn>; request: EIP1193RequestFn; value?: Record<string, any> | undefined; } {
      throw new Error('Function not implemented.');
    },
    252: function (params: { chain?: Chain | undefined; pollingInterval?: number | undefined; retryCount?: number | undefined; timeout?: number | undefined; } & { connectors?: StoreApi<Connector[]> | undefined; }): { config: TransportConfig<string, EIP1193RequestFn>; request: EIP1193RequestFn; value?: Record<string, any> | undefined; } {
      throw new Error('Function not implemented.');
    },
    122: function (params: { chain?: Chain | undefined; pollingInterval?: number | undefined; retryCount?: number | undefined; timeout?: number | undefined; } & { connectors?: StoreApi<Connector[]> | undefined; }): { config: TransportConfig<string, EIP1193RequestFn>; request: EIP1193RequestFn; value?: Record<string, any> | undefined; } {
      throw new Error('Function not implemented.');
    },
    59144: function (params: { chain?: Chain | undefined; pollingInterval?: number | undefined; retryCount?: number | undefined; timeout?: number | undefined; } & { connectors?: StoreApi<Connector[]> | undefined; }): { config: TransportConfig<string, EIP1193RequestFn>; request: EIP1193RequestFn; value?: Record<string, any> | undefined; } {
      throw new Error('Function not implemented.');
    },
    5000: function (params: { chain?: Chain | undefined; pollingInterval?: number | undefined; retryCount?: number | undefined; timeout?: number | undefined; } & { connectors?: StoreApi<Connector[]> | undefined; }): { config: TransportConfig<string, EIP1193RequestFn>; request: EIP1193RequestFn; value?: Record<string, any> | undefined; } {
      throw new Error('Function not implemented.');
    },
    1088: function (params: { chain?: Chain | undefined; pollingInterval?: number | undefined; retryCount?: number | undefined; timeout?: number | undefined; } & { connectors?: StoreApi<Connector[]> | undefined; }): { config: TransportConfig<string, EIP1193RequestFn>; request: EIP1193RequestFn; value?: Record<string, any> | undefined; } {
      throw new Error('Function not implemented.');
    },
    34443: function (params: { chain?: Chain | undefined; pollingInterval?: number | undefined; retryCount?: number | undefined; timeout?: number | undefined; } & { connectors?: StoreApi<Connector[]> | undefined; }): { config: TransportConfig<string, EIP1193RequestFn>; request: EIP1193RequestFn; value?: Record<string, any> | undefined; } {
      throw new Error('Function not implemented.');
    },
    1285: function (params: { chain?: Chain | undefined; pollingInterval?: number | undefined; retryCount?: number | undefined; timeout?: number | undefined; } & { connectors?: StoreApi<Connector[]> | undefined; }): { config: TransportConfig<string, EIP1193RequestFn>; request: EIP1193RequestFn; value?: Record<string, any> | undefined; } {
      throw new Error('Function not implemented.');
    },
    1284: function (params: { chain?: Chain | undefined; pollingInterval?: number | undefined; retryCount?: number | undefined; timeout?: number | undefined; } & { connectors?: StoreApi<Connector[]> | undefined; }): { config: TransportConfig<string, EIP1193RequestFn>; request: EIP1193RequestFn; value?: Record<string, any> | undefined; } {
      throw new Error('Function not implemented.');
    },
    10: function (params: { chain?: Chain | undefined; pollingInterval?: number | undefined; retryCount?: number | undefined; timeout?: number | undefined; } & { connectors?: StoreApi<Connector[]> | undefined; }): { config: TransportConfig<string, EIP1193RequestFn>; request: EIP1193RequestFn; value?: Record<string, any> | undefined; } {
      throw new Error('Function not implemented.');
    },
    137: function (params: { chain?: Chain | undefined; pollingInterval?: number | undefined; retryCount?: number | undefined; timeout?: number | undefined; } & { connectors?: StoreApi<Connector[]> | undefined; }): { config: TransportConfig<string, EIP1193RequestFn>; request: EIP1193RequestFn; value?: Record<string, any> | undefined; } {
      throw new Error('Function not implemented.');
    },
    1101: function (params: { chain?: Chain | undefined; pollingInterval?: number | undefined; retryCount?: number | undefined; timeout?: number | undefined; } & { connectors?: StoreApi<Connector[]> | undefined; }): { config: TransportConfig<string, EIP1193RequestFn>; request: EIP1193RequestFn; value?: Record<string, any> | undefined; } {
      throw new Error('Function not implemented.');
    },
    30: function (params: { chain?: Chain | undefined; pollingInterval?: number | undefined; retryCount?: number | undefined; timeout?: number | undefined; } & { connectors?: StoreApi<Connector[]> | undefined; }): { config: TransportConfig<string, EIP1193RequestFn>; request: EIP1193RequestFn; value?: Record<string, any> | undefined; } {
      throw new Error('Function not implemented.');
    },
    534352: function (params: { chain?: Chain | undefined; pollingInterval?: number | undefined; retryCount?: number | undefined; timeout?: number | undefined; } & { connectors?: StoreApi<Connector[]> | undefined; }): { config: TransportConfig<string, EIP1193RequestFn>; request: EIP1193RequestFn; value?: Record<string, any> | undefined; } {
      throw new Error('Function not implemented.');
    },
    713715: function (params: { chain?: Chain | undefined; pollingInterval?: number | undefined; retryCount?: number | undefined; timeout?: number | undefined; } & { connectors?: StoreApi<Connector[]> | undefined; }): { config: TransportConfig<string, EIP1193RequestFn>; request: EIP1193RequestFn; value?: Record<string, any> | undefined; } {
      throw new Error('Function not implemented.');
    },
    324: function (params: { chain?: Chain | undefined; pollingInterval?: number | undefined; retryCount?: number | undefined; timeout?: number | undefined; } & { connectors?: StoreApi<Connector[]> | undefined; }): { config: TransportConfig<string, EIP1193RequestFn>; request: EIP1193RequestFn; value?: Record<string, any> | undefined; } {
      throw new Error('Function not implemented.');
    },
    56: function (params: { chain?: Chain | undefined; pollingInterval?: number | undefined; retryCount?: number | undefined; timeout?: number | undefined; } & { connectors?: StoreApi<Connector[]> | undefined; }): { config: TransportConfig<string, EIP1193RequestFn>; request: EIP1193RequestFn; value?: Record<string, any> | undefined; } {
      throw new Error('Function not implemented.');
    }
  },
  connectors,
  batch: {
    multicall: true,
  },
})

export const PythMainnetUrl = "https://perennial.rpc.p2p.world"
export const PythTestnetUrl = "https://perennial.rpc.p2p.world"
export const CryptexPricesUrl = "https://api.perps.cryptex.finance/"
// export const CryptexPriceFeedUrl = 'https://api.perps.cryptex.finance/price-feed'
export const CryptexPriceFeedUrl = 'https://perps-a701303cff4d.herokuapp.com/price-feed'

const PythBenchmarkUrlVersion = 'v1'
const PythBenchmarkUrl = "benchmarks.pyth.network" //process.env.REACT_APP_PUBLIC_PYTH_DATAFEED_URL
export const PythDataFeedUrl = `https://${PythBenchmarkUrl}/${PythBenchmarkUrlVersion}/shims/tradingview`
export const PythPriceFeedUrl = `https://${PythBenchmarkUrl}/${PythBenchmarkUrlVersion}/price_feeds`

export const BackupPythClient = new HermesClient(
  `${typeof window !== 'undefined' ? window.location.origin : 'https://app.perennial.finance'}/api/pyth`,
  {
    timeout: 30000,
  },
)

export const metamaskTxRejectedError = "user rejected"

export const BetaWhitelist = [
  "0xd4fa23307a181B9ca567886eB5bCd5c8f8f8bB3E",
  "0xF6a16a48099497C59e8abEAa37Bb37B2F9B793d4",
  // users
  "0x9d6c8A3D01aD34fdD3e9146ecb84ce6261265fA5",
  "0xCFa41609dbB5124e7277CA60b52D8981D3307889",
  "0x097c39E5E576A8706404CD0D81e05b522f5bCAfF",
  "0xeF05eACCF1850557B0Ea561E54fBbA194501C89C",
  "0x6b116f29ea8e4a0d7e3938cadc8bf0368f8f16fe",
  "0x391BD97fe4353Dc5D56Fd03b9F8b4dB790B4e668",
  "0x4AdA1B9D9fe28aBd9585f58cfEeD2169A39e1c6b",
  "0xbBe844467051fE04CCE050efB42cF824431d1e52",
  "0x51d93270eA1aD2ad0506c3BE61523823400E114C",
  "0xf5de0FCC2679A1113d60841075DD46e85D323701",
  "0x9305ee3aDAcf82fF5De65096B201C0441bC5eE12",
  "0x9eeCEEC01aC7bE64C747B235552A2357af8534c1",
  "0xEAd4F71860fBb073d01C0EA5Bc406Cb1c1B1184A",
  "0x7582868a0133028aAf8f4F615e4D8E8cF642eb9b",
  "0xcF3E2Ee2b0cD1194ba33cD1c7d9614DC635a1714",
  "0xeA99bF156F30bdC748d452D8A4e178fBE762762d",
  "0x4B8485a29a0C887780C47D6c9D76F02547A0868A",
  "0x5a4578545F58f23bc5B49478f8E16993865f089D",
  "0x9aef90411Efc0ea34Ef7408D727970C2a423DCFD",
  "0x2Dac41C6f487F8Fd138658945BD3098c7fbeE86E",
]
